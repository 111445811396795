import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../API/authapi.tsx";
import CreatableSelect from "react-select/creatable";
import { useNavigate, useParams } from "react-router-dom";

function UpdateExpense() {
  const navigate = useNavigate();
  const { ExpenseId } = useParams();
  const [expense, setExpense] = useState("");
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [rating, setRating] = useState("");
  const [payment, setPayment] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch category options
    const fetchCategoryData = async () => {
      try {
        const response = await axiosInstance.post('/expense/getcategory');
        setCategory(response.data.map((cat) => ({ value: cat, label: cat })));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          console.error('Error fetching categories:', error);
        }
      }
    };

    // Fetch existing expense data
    const fetchExpenseData = async () => {
      try {
        const response = await axiosInstance.get(`/expense/${ExpenseId}`);
        const { data } = response;
        setExpense(data.type);
        setAmount(data.amount);
        setSelectedCategory({ value: data.category, label: data.category });
        setRating(data.expensetype);
        setPayment(data.payment);
      } catch (error) {
        console.error('Error fetching expense data:', error);
        toast.error("Error fetching expense data");
      }
    };

    fetchCategoryData();
    fetchExpenseData();
  }, [ExpenseId, navigate]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: state.isFocused ? '1px solid #93C5FD' : '1px solid #D1D5DB',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #93C5FD',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    input: (provided) => ({
      ...provided,
      color: 'black',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#6B7280' : 'transparent',
      color: state.isFocused ? '#F3F4F6' : '#4B5563',
    }),
  };

  const handleCreateCategory = (newCategory) => {
    const newOption = { value: newCategory, label: newCategory };
    setCategory((prevCategories) => [...prevCategories, newOption]);
    setSelectedCategory(newOption);
  };

  const handleUpdateExpense = () => {
    // Validation
    if (!expense || !amount || !selectedCategory || !rating || !payment) {
      toast.error("Please fill out all fields and select valid options");
      return;
    }

    setLoading(true);

    const expenseData = {
      type: expense,
      amount: parseFloat(amount),
      category: selectedCategory.value,
      expensetype: rating,
      payment,
    };

    axiosInstance
      .put(`/expense/updateexpense/${ExpenseId}`, expenseData)
      .then((response) => {
        console.log("Expense updated successfully:", response.data);
        toast.success("Expense updated successfully");
        navigate('/expenses');
      })
      .catch((error) => {
        console.error("Error updating expense:", error);
        toast.error("Error updating expense");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteExpense = () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this expense?');

    if (isConfirmed) {
      axiosInstance
        .delete(`/expense/${ExpenseId}`)
        .then((response) => {
          toast.success("Expense deleted successfully");
          navigate('/expenses');
        })
        .catch((error) => {
          toast.error("Error deleting expense");
        });
    }
  };

  return (
    <div className="relative w-full p-10 flex flex-col gap-4 bg-white pb-20 h-full font-rubik min-h-screen z-10">
      <div>
        <h1 className="text-4xl lg:text-6xl font-rubik mb-7 font-bold text-black">
          Update Expense
        </h1>
      </div>

      <div className="relative group rounded-lg w-full bg-opacity-20 flex flex-col gap-6">
        <div>
          <h2 className="text-lg font-semibold text-black mb-2">Expense Name</h2>
          <input
            value={expense}
            onChange={(e) => setExpense(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-black bg-[#f9f9f9] ring-0 outline-none text-neutral-900 placeholder-[#888888] placeholder-opacity-50 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-4"
            placeholder="Enter expense"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-black mb-2">Amount</h2>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-black bg-[#f9f9f9] ring-0 outline-none text-neutral-900 placeholder-[#888888] placeholder-opacity-50 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-4"
            placeholder="Enter Amount"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-black mb-2">Category</h2>
          <CreatableSelect
            options={category}
            isClearable
            styles={customStyles}
            onChange={(selectedOption) => setSelectedCategory(selectedOption)}
            onCreateOption={handleCreateCategory}
            placeholder="Select or type a category"
            isSearchable
            value={selectedCategory}
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-black mb-2">Rating</h2>
          <select
            value={rating}
            onChange={(e) => setRating(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-black bg-[#f9f9f9] ring-0 outline-none text-neutral-900 placeholder-[#888888] placeholder-opacity-50 text-sm font-bold rounded-lg focus:ring-black focus:border-violet-500 block w-full p-4"
          >
            <option>Select Type</option>
            <option>Good</option>
            <option>Bad</option>
          </select>
        </div>
        <div>
          <h2 className="text-lg font-semibold text-black mb-2">Payment Method</h2>
          <select
            value={payment}
            onChange={(e) => setPayment(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-black bg-[#f9f9f9] ring-0 outline-none text-neutral-900 placeholder-[#888888] placeholder-opacity-50 text-sm font-bold rounded-lg focus:ring-black focus:border-violet-500 block w-full p-4"
          >
            <option>Select Payment Method</option>
            <option>Cash</option>
            <option>Bank</option>
          </select>
        </div>
        <button
          onClick={handleUpdateExpense}
          disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-black px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 justify-center items-center ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Updating..." : "Update"}
        </button>
        <button
          onClick={handleDeleteExpense}
          disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-gradient-to-r from-red-600 to-red-900 px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 hover:from-slate-800 hover:to-gray-900 justify-center items-center bg-opacity-10 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Deleting..." : "Delete Expense"}
        </button>
      </div>
    </div>
  );
}

export default UpdateExpense;