import { ArrowLeft } from 'lucide-react';
import React from 'react';

const BackButton = ({ theme }) => {
  const handleGoBack = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <button
      onClick={handleGoBack}
      className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
    >
      <ArrowLeft className={`h-6 w-6 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`} />
    </button>
  );
};

export default BackButton;
