import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../API/authapi.tsx";
import CreatableSelect from "react-select/creatable";
import { useNavigate, useParams } from "react-router-dom";

function UpdateProfit() {
  const navigate = useNavigate();
  const { profitId } = useParams();
  const [profit, setProfit] = useState("");
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [payment, setPayment] = useState("");
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/profit/${profitId}`);
        const { data } = response;
        setProfit(data.type);
        setAmount(data.amount);
        setSelectedCategory({ value: data.category, label: data.category });
        setPayment(data.payment);
      } catch (error) {
        console.error('Error fetching profit data:', error);
        toast.error("Error fetching profit data");
      }
    };
    fetchData();
  }, [profitId]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: state.isFocused ? '1px solid #93C5FD' : '1px solid #D1D5DB',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #93C5FD',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    input: (provided) => ({
      ...provided,
      color: 'black',  // Set the text color to white
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',  // Set the selected option text color to white
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#6B7280' : 'transparent',
      color: state.isFocused ? '#F3F4F6' : '#4B5563',
    }),
  };

  const handleCreateCategory = (newCategory) => {
    const newOption = { value: newCategory, label: newCategory };
    setSelectedCategory(newOption);
  };

  const handleDeleteProfit = () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this customer?');

    if (isConfirmed) {
      // Proceed with the delete operation
      // Make the API request to delete the data
      axiosInstance
        .delete(`/profit/${profitId}`)
        .then((response) => {
          toast.success("Customer deleted successfully");
        })
        .catch((error) => {
          toast.error("Error deleting customer");
        });
    }
  };

  const handleUpdateProfit = () => {
    setLoading(true);
    const profitData = {
      type: profit,
      amount: parseFloat(amount),
      category: selectedCategory.value,
      payment,
    };

    axiosInstance
      .put(`/profit/updateprofit/${profitId}`, profitData)
      .then((response) => {
        console.log("Profit updated successfully:", response.data);
        toast.success("Profit updated successfully");
      })
      .catch((error) => {
        console.error("Error updating Profit:", error);
        toast.error("Error updating Profit");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="relative w-full p-10 flex flex-col gap-4 bg-white pb-20 h-full font-rubik min-h-screen z-10">

      <div>
        <h1 className="text-4xl lg:text-6xl font-rubik mb-7 font-bold text-black ">
          Update Profit
        </h1>
      </div>

      <div className="relative group rounded-lg w-full bg-opacity-20 flex flex-col gap-6">
        <div>
          <h2 className="text-lg font-semibold text-black mb-2">Profit Name</h2>
          <input
            name=""
            id=""
            value={profit}
            onChange={(e) => setProfit(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-black bg-[#f9f9f9] ring-0 outline-none text-neutral-900 placeholder-[#888888]  placeholder-opacity-50 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-4"
            placeholder="Enter Profit"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-black mb-2">Amount</h2>
          <input
            name=""
            id=""
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-black bg-[#f9f9f9] ring-0 outline-none text-neutral-900 placeholder-[#888888]  placeholder-opacity-50 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-4"
            placeholder="Enter Amount"
          />
        </div>
        <div>
          <div>
            <h2 className="text-lg font-semibold text-black mb-2">Category</h2>
            <CreatableSelect
              options={category}
              isClearable
              styles={customStyles}
              onChange={(selectedOption) => setSelectedCategory(selectedOption)}
              onCreateOption={handleCreateCategory}
              
              placeholder="Select or type a category"
              isSearchable
              value={selectedCategory}
            />
          </div>
          {/* Allow users to input a custom category */}

        </div>
        <div>
          <h2 className="text-lg font-semibold text-black mb-2">Payment Method</h2>
          <select
            name=""
            id=""
            value={payment}
            onChange={(e) => setPayment(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-300 bg-[#f9f9f9] ring-0 outline-none text-neutral-900 placeholder-[#888888]  placeholder-opacity-50 text-sm font-bold rounded-lg focus:ring-black focus:border-violet-500 block w-full p-4"
            placeholder="Select Payment Method"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select Payment Method</option>
            <option className="bg-neutral-700 ">Cash</option>
            <option className="bg-neutral-700">Bank</option>
          </select>
        </div>
        <button
          onClick={handleUpdateProfit}
          disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-black px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 justify-center items-center ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Updating..." : "Update"}
        </button>
        <button
          onClick={handleDeleteProfit}
          disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-gradient-to-r from-red-600 to-red-900 px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 hover:from-slate-800 hover:to-gray-900 justify-center items-center bg-opacity-10 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Deleting..." : "Delete Profit"}
        </button>

      </div>
    </div>
  );


}

export default UpdateProfit;