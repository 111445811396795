import React, { useState, useEffect } from "react";
import Lossgraph from "../Lossgraph";
import ProgressBar from "@ramonak/react-progress-bar";
import { axiosInstance } from "../../API/authapi.tsx";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import HistoricalReportGraph from "../../Components/graph.jsx";
import { ArrowLeft } from "lucide-react";
import BackButton from "../../Components/BackButton.jsx";
import DateRangePicker from "../../Components/DateRangePicker.jsx";


function Profit() {
  const navigate = useNavigate();
  const [profitAmount, setProfitAmount] = useState(0);
  const [showGoodExpenses, setShowGoodExpenses] = useState(true);
  const [showBadExpenses, setShowBadExpenses] = useState(false);
  const [profitData, setProfitData] = useState([]);

  const currentDate = new Date();

  // Calculate the first day of the current month
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Format the date as "YYYY-MM-DD" for the input value
  const initialStartDate = formatDate(firstDayOfMonth);
  const initialEndDate = formatDate(currentDate);

  // Function to format the date as "YYYY-MM-DD"
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const toggleExpenses = (type) => {
    setShowGoodExpenses(type === "good");
    setShowBadExpenses(type === "bad");
  };
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark"; // Default to dark theme if no preference is saved
  };

  const [theme, setTheme] = useState(getInitialTheme());
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmountByCategory, setTotalAmountByCategory] = useState([]);




  const deleteExpense = (index) => {
    const updatedExpenses = [...profitData];
    updatedExpenses.splice(index, 1);
    setProfitData(updatedExpenses);
  };

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);



  const handleDateRangeChange = (dates) => {
    console.log(dates);

    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start.toISOString()); // Update startDate
      setEndDate(end.toISOString()); // Update endDate
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date();
        const start = startDate || today.toISOString();
        const end = endDate || today.toISOString();
        
        const response = await axiosInstance.post('/profit/totalprofitdetails', {
          startDate: start,
          endDate: end,
        });

        const { totalProfit, profitDataByCategory } = response.data;

        setProfitData(profitDataByCategory)

        if (totalProfit === null) {
          setProfitAmount(0);
        } else {
          setProfitAmount(totalProfit);
        }

        // Calculate total amount for each category
        const totalAmountByCategory = profitDataByCategory.reduce((acc, curr) => {
          acc[curr.category] = (acc[curr.category] || 0) + parseFloat(curr.amount);
          return acc;
        }, {});

        // Transform totalAmountByCategory object into an array of objects
        const totalAmountArray = Object.keys(totalAmountByCategory).map(category => ({
          category: category,
          totalAmount: totalAmountByCategory[category]
        }));

        // Update state with the total amount for each category
        setTotalAmountByCategory(totalAmountArray);
        console.log(totalAmountByCategory);

        setIsLoading(true);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          console.error('Error fetching financial overview:', error);
        }
      }
    };

    fetchData();
  }, [startDate,endDate,navigate]); // Fetch data when startDate or endDate changes


  if (!isLoading) {
    console.log(isLoading);
    return (
      <div className={`${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'} flex justify-center items-center h-screen`}>
        <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-black'}`}>Loading...</p>
      </div>

    );
  }
  return (
    <div className={`relative min-h-screen w-full flex flex-col gap-4 ${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'}`}>

      <div className="flex items-center justify-between px-4 py-2">
        <BackButton />

        <h1 className={`text-2xl lg:text-6xl font-rubik font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-gray-900'}`}>
          Profit
        </h1>

        <DateRangePicker onChange={handleDateRangeChange}/>
      </div>

      <div className="w-full p-4">
        <div className="bg-[#3c66f6c7] text-black rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-36 font-rubik h-44 relative overflow-hidden ">
          <div className=" bg-white bg-opacity-10 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
          <div className=" bg-white bg-opacity-10 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
          <p className={`font-light mb-4  ${theme === 'dark' ? 'text-slate-300' : 'text-white'}`}>Your Total Profit</p>
          <h3 className="font-bold text-white text-5xl md:text-4xl lg:text-6xl">
            <span className="font-poppins text-white text-4xl md:text-2xl mr-1">₹</span>
            {profitAmount}
          </h3>
        </div>
      </div>
      <HistoricalReportGraph />
      <div className=" text-blue-200 w-full rounded-3xl p-6 lg:p-10 flex flex-col justify-center items-start lg:h-36 relative">
        <div className="flex justify-center w-full  text-lg font-rubik">
          <button onClick={() => toggleExpenses("good")} className={theme === 'dark' ? ' text-white ' : ' text-black font-semibold font-rubik'} >
            Profit Category
          </button>
          {/* <button onClick={() => toggleExpenses("bad")} className={theme === 'dark' ? 'bg-slate-700 text-white' : 'bg-black-900 text-white'}>
  Bad Expenses
</button> */}


        </div>

        {totalAmountByCategory && (
          <div className="w-full mt-7 -z-0">
            {totalAmountByCategory.map((profit, index) => (
              <div
                key={index}
                className="w-full border border-[#e4e4e4] border-solid bg-[#f0f0f0] rounded-2xl text-white p-5 relative mb-5 pb-8"
              >
                <div className="flex w-full gap-5">
                  <Link to={`/profits?category=${profit.category}`} className="bg-[#fff] w-12 h-12 rounded flex justify-center items-center">
                    {/* You can replace this with your SVG icon */}
                    <span>
                      <svg
                        width="512"
                        height="512"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                      >
                        <path
                          fill="#000"
                          d="M256 640v192h640V384H768v-64h150.976c14.272 0 19.456 1.472 24.64 4.288a29.056 29.056 0 0 1 12.16 12.096c2.752 5.184 4.224 10.368 4.224 24.64v493.952c0 14.272-1.472 19.456-4.288 24.64a29.056 29.056 0 0 1-12.096 12.16c-5.184 2.752-10.368 4.224-24.64 4.224H233.024c-14.272 0-19.456-1.472-24.64-4.288a29.056 29.056 0 0 1-12.16-12.096c-2.688-5.184-4.224-10.368-4.224-24.576V640z"
                        />
                        <path
                          fill="#000"
                          d="M768 192H128v448h640zm64-22.976v493.952c0 14.272-1.472 19.456-4.288 24.64a29.056 29.056 0 0 1-12.096 12.16c-5.184 2.752-10.368 4.224-24.64 4.224H105.024c-14.272 0-19.456-1.472-24.64-4.288a29.056 29.056 0 0 1-12.16-12.096C65.536 682.432 64 677.248 64 663.04V169.024c0-14.272 1.472-19.456 4.288-24.64a29.056 29.056 0 0 1 12.096-12.16C85.568 129.536 90.752 128 104.96 128h685.952c14.272 0 19.456 1.472 24.64 4.288a29.056 29.056 0 0 1 12.16 12.096c2.752 5.184 4.224 10.368 4.224 24.64z"
                        />
                        <path
                          fill="#000"
                          d="M448 576a160 160 0 1 1 0-320a160 160 0 0 1 0 320m0-64a96 96 0 1 0 0-192a96 96 0 0 0 0 192"
                        />
                      </svg>
                    </span>
                  </Link>
                  <div>
                    <p className="font-rubik text-lg text-black font-bold">{profit.category}</p>
                    <p className="font-poppins text-lg text-black">₹{profit.totalAmount}</p>
                    {/* <p className="-mt-1 text-slate-500 text-sm">
                      {expense.subTitle}
                    </p> */}
                  </div>
                  <div className="absolute right-3 top-3">
                    {/* delete button */}
                    <svg
                      width="512"
                      height="512"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 text-slate-500"
                      onClick={() => deleteExpense(index)}
                    >
                      <path
                        fill="currentColor"
                        d="M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Profit;
