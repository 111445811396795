import React, { useState, useEffect } from "react";
import Lossgraph from "../Lossgraph";
import { ArrowLeft } from "lucide-react"
import { axiosInstance } from "../../API/authapi.tsx";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import HistoricalReportGraph from "../../Components/graph.jsx";
import BackButton from "../../Components/BackButton.jsx";


function Invest() {
  const navigate = useNavigate(); 
  const [investAmount, setInvestAmount] = useState(0);
  const [showGoodExpenses, setShowGoodExpenses] = useState(true);
  const [showBadExpenses, setShowBadExpenses] = useState(false);
  const [investData, setInvestData] = useState([]);




  const deleteExpense = (index) => {
    const updatedExpenses = [...investData];
    updatedExpenses.splice(index, 1);
    setInvestData(updatedExpenses);
  };

  // const deleteBadExpense = (index) => {
  //   const updatedExpenses = [...badExpense];
  //   updatedExpenses.splice(index, 1);
  //   setBadExpense(updatedExpenses);
  // };
  const currentDate = new Date();

  // Calculate the first day of the current month
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Format the date as "YYYY-MM-DD" for the input value
  const initialStartDate = formatDate(firstDayOfMonth);

  // Function to format the date as "YYYY-MM-DD"
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [startDate, setStartDate] = useState(initialStartDate);

  const toggleExpenses = (type) => {
    setShowGoodExpenses(type === "good");
    setShowBadExpenses(type === "bad");
  };
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark"; // Default to dark theme if no preference is saved
  };
  
  const [theme, setTheme] = useState(getInitialTheme());
  const [isLoading, setIsLoading] = useState(true);


  const getEllipseSvg = () => {
    const darkTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_45_348)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_45_348)" />
        </g>
        <defs>
          <filter id="filter0_f_45_348" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_348" />
          </filter>
          <linearGradient id="paint0_linear_45_348" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#070E23" />
            <stop offset="1" stop-color="#2D303E" />
          </linearGradient>
        </defs>
      </svg>
    );
    const lightTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_3_4)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_3_4)" />
        </g>
        <defs>
          <filter id="filter0_f_3_4" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_4" />
          </filter>
          <linearGradient id="paint0_linear_3_4" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#A9BCFF" />
            <stop offset="1" stop-color="#CECDFC" />
          </linearGradient>
        </defs>
      </svg>
    );
    return theme === 'dark' ? darkTheme : lightTheme;
  }

  const getCircleSvg = () => {
    const darkTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_45_347)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_45_347)" />
        </g>
        <defs>
          <filter id="filter0_f_45_347" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_347" />
          </filter>
          <linearGradient id="paint0_linear_45_347" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#392A40" />
            <stop offset="1" stop-color="#24236A" />
          </linearGradient>
        </defs>
      </svg>
    );

    const lightTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_3_3)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_3_3)" />
        </g>
        <defs>
          <filter id="filter0_f_3_3" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_3" />
          </filter>
          <linearGradient id="paint0_linear_3_3" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E3A9FF" />
            <stop offset="1" stop-color="#ABA9FF" />
          </linearGradient>
        </defs>
      </svg>
    );

    return theme === 'dark' ? darkTheme : lightTheme;
  };


  useEffect(() => {

    const fetchData = async () => {
      try {
        const today = new Date();

        const response = await axiosInstance.post('/investment/totalinvestmentsdata', {
          startDate: "2023-11-01",
          endDate: today.toISOString(),
        });
        console.log(response.data);

        // Assuming your API returns the data in the format { totalExpense, allExpenseData }
        const { totalInvest, allInvestData } = response.data;

       if(totalInvest===null){
        setInvestAmount(0);
        setInvestData(allInvestData)
        
        
      } 
      else{
        setInvestAmount(totalInvest)
        setInvestData(allInvestData)
      }
      setIsLoading(false)
          } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }      }
    };

    fetchData();
  }, [startDate]); // Fetch data when startDate or endDate changes
  if (isLoading) {
    console.log(isLoading);
    return (
      <div className={`${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'} flex justify-center items-center h-screen`}>
      <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-black'}`}>Loading...</p>
    </div>

    );
  }
  return (
    <div className={`relative min-h-screen p-6 w-full flex flex-col ${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'}`}>
      <div className="flex items-center justify-between px-4 py-2">
      <BackButton/>

      <h1 className={`text-2xl lg:text-6xl font-rubik font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-gray-900'}`}>
        Investment
      </h1>
      <div className="w-6" /> {/* This empty div balances the layout */}
    </div>
      <div className="w-full p-4 ">
        <div className="BalanceBlue text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-36 font-rubik h-44 relative overflow-hidden ">
          <div className=" bg-slate-50 bg-opacity-10 w-28 h-28 rounded-full absolute  -top-4 right-5       -z-0 "></div>
          <div className=" bg-slate-50 bg-opacity-10 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
          <p className={`font-light mb-4  ${theme === 'dark' ? 'text-black' : 'text-[#9c9c9c]'}`}>Your Total Investment</p>
          <h3 className="font-bold text-4xl md:text-4xl lg:text-4xl">
            <span className="font-poppins text-4xl md:text-2xl mr-1">₹</span>
            {investAmount}
          </h3>
        </div> 
      </div>
      <HistoricalReportGraph/>

      <div className=" text-blue-200 w-full p-4 rounded-3xl flex flex-col justify-center items-start lg:h-36 relative">
        <div className="flex justify-between w-full mt-3  text-lg font-rubik">
        <button  onClick={() => toggleExpenses("good")} className={theme === 'dark' ? ' text-white ' : ' text-black font-semibold font-rubik' } >
  Investment Category
</button>

        </div>

        {investData && (
          <div className="w-full mt-4 -z-0">
            {investData.map((invest, index) => (
              <div
                key={index}
                className="w-full border border-[#e4e4e4] border-solid bg-[#f0f0f0] rounded-2xl text-white p-5 relative mb-5 pb-5"
              >
                <div className="flex w-full gap-5">
                  <div className="bg-[#fff] w-12 h-12 rounded-lg  flex justify-center items-center">
                    {/* You can replace this with your SVG icon */}
                    <span>
                      <svg
                        width="512"
                        height="512"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                      >
                        <path
                          fill="#000"
                          d="M256 640v192h640V384H768v-64h150.976c14.272 0 19.456 1.472 24.64 4.288a29.056 29.056 0 0 1 12.16 12.096c2.752 5.184 4.224 10.368 4.224 24.64v493.952c0 14.272-1.472 19.456-4.288 24.64a29.056 29.056 0 0 1-12.096 12.16c-5.184 2.752-10.368 4.224-24.64 4.224H233.024c-14.272 0-19.456-1.472-24.64-4.288a29.056 29.056 0 0 1-12.16-12.096c-2.688-5.184-4.224-10.368-4.224-24.576V640z"
                        />
                        <path
                          fill="#000"
                          d="M768 192H128v448h640zm64-22.976v493.952c0 14.272-1.472 19.456-4.288 24.64a29.056 29.056 0 0 1-12.096 12.16c-5.184 2.752-10.368 4.224-24.64 4.224H105.024c-14.272 0-19.456-1.472-24.64-4.288a29.056 29.056 0 0 1-12.16-12.096C65.536 682.432 64 677.248 64 663.04V169.024c0-14.272 1.472-19.456 4.288-24.64a29.056 29.056 0 0 1 12.096-12.16C85.568 129.536 90.752 128 104.96 128h685.952c14.272 0 19.456 1.472 24.64 4.288a29.056 29.056 0 0 1 12.16 12.096c2.752 5.184 4.224 10.368 4.224 24.64z"
                        />
                        <path
                          fill="#000"
                          d="M448 576a160 160 0 1 1 0-320a160 160 0 0 1 0 320m0-64a96 96 0 1 0 0-192a96 96 0 0 0 0 192"
                        />
                      </svg>
                    </span>
                  </div>
                  <div>
                    <p className="font-rubik text-lg text-black font-bold">{invest.type}</p>
                    <p className="font-poppins text-sm text-black">₹{invest.amount}</p>
                    {/* <p className="-mt-1 text-slate-500 text-sm">
                      {expense.subTitle}
                    </p> */}
                  </div>
                  <div className="absolute right-3 top-3">
                    {/* delete button */}
                    <svg
                      width="512"
                      height="512"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 text-slate-500"
                      onClick={() => deleteExpense(index)}
                    >
                      <path
                        fill="currentColor"
                        d="M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>                        
    </div>
  );
}

export default Invest;
