import { motion } from 'framer-motion'

export default function MoneyFlowScreen() {
  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden font-sans">
      {/* Decorative shapes */}
      <motion.div 
        className="absolute inset-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <motion.div 
          className="absolute w-64 h-64 bg-yellow-200/60 rounded-full blur-2xl -top-32 -right-32"
          animate={{ 
            scale: [1, 1.1, 1],
            rotate: [0, 10, 0],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
        <motion.div 
          className="absolute w-64 h-64 bg-green-200/60 rounded-full blur-2xl -top-20 -left-32"
          animate={{ 
            scale: [1, 1.2, 1],
            rotate: [0, -10, 0],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
        <motion.div 
          className="absolute w-64 h-64 bg-pink-200/60 rounded-full blur-2xl bottom-0 right-0"
          animate={{ 
            scale: [1, 1.1, 1],
            rotate: [0, -5, 0],
          }}
          transition={{
            duration: 9,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
      </motion.div>

      {/* Content */}
      <div className="relative min-h-screen flex flex-col justify-between p-8 max-w-md mx-auto">
        <header className="text-sm text-gray-400 tracking-wide">
        </header>

        <main className="flex flex-col gap-6 mb-12">
          <motion.h1 
            className="text-4xl font-bold leading-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            Master Your<br />Money Flow
          </motion.h1>
          <motion.p 
            className="text-gray-400 text-lg leading-relaxed"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            Track expenses, monitor profits, and manage your finances effortlessly.
          </motion.p>
        </main>

        <motion.button 
          className="w-full bg-white text-black py-4 rounded-2xl font-semibold text-lg mb-8 hover:bg-gray-100 transition-colors focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          whileTap={{ scale: 0.98 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
  <a href="/#/finance" className="block w-full h-full">Get Started</a>
  </motion.button>
      </div>
    </div>
  )
}

