import React, { useState, useEffect } from 'react'
import { X, ShoppingBag, CreditCard, PiggyBank, TrendingUp, Heart, CircleDollarSign, Home, Cog, FileText, BarChart, User, LogOut } from 'lucide-react'

export default function MobileAddButton() {
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  const togglePopup = () => {
    if (isOpen) {
      setIsVisible(false);
      setTimeout(() => setIsOpen(false), 500);
    } else {
      setIsOpen(true);
      setTimeout(() => setIsVisible(true), 10);
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentHash(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  if (currentHash === '' || currentHash === '#/accounts' || currentHash === "#/") {
    return null;
  }

  const handleLogout = () => {
    // Clear JWT token from localStorage or cookies
    localStorage.removeItem('JWT_token'); // Replace with your token's storage key
  
    // Redirect the user to the login page and reload the page
    window.location.href = '/#/accounts';
    window.location.reload(); // Ensures the page fully reloads after redirect
  };
  
  return (
    <>
      {/* Full-Screen Modal */}
      {isOpen && (
        <>
          {/* Backdrop */}
          <div
            onClick={togglePopup}
            className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
          />

          {/* Modal Content with Slide-up Animation */}
          <div
            className={`fixed bottom-0 left-0 right-0 bg-white pt-5 pb-10 rounded-t-3xl shadow-lg z-50 transition-transform duration-500 ${
              isVisible ? 'translate-y-0' : 'translate-y-full'
            }`}
          >
            {/* Modal Title */}
            <h2 className="text-xl font-bold mb-6 text-center">Add</h2>

            {/* Grid Buttons */}
            <div className="grid grid-cols-3 gap-4 mb-6 px-6">
              <a href='/#/addprofit' className="flex flex-col items-center justify-center h-28 p-3 bg-green-100 rounded-xl cursor-pointer">
                <CircleDollarSign strokeWidth={2} className="w-7 h-7 text-current" />
                <span className="mt-2 text-sm font-semibold">Add Profit</span>
              </a>
              <a href='/#/addexpense' className="flex flex-col items-center p-3 bg-red-100 rounded-xl cursor-pointer justify-center h-28">
                <ShoppingBag className="w-6 h-6 text-current" />
                <span className="mt-2 text-sm font-semibold">Add Expense</span>
              </a>
              <a href='/#/addcredit' className="flex flex-col justify-center h-28 items-center p-3 bg-blue-100 rounded-xl cursor-pointer">
                <CreditCard className="w-6 h-6 text-current" />
                <span className="mt-2 text-sm font-semibold">Add Credits</span>
              </a>
              <a href='/#/addsaving' className="flex flex-col items-center justify-center h-28 p-3 bg-yellow-100 rounded-xl cursor-pointer">
                <PiggyBank className="w-6 h-6 text-current" />
                <span className="mt-2 text-sm font-semibold">Add Savings</span>
              </a>
              <a href='/#/addinvestment' className="flex flex-col items-center justify-center h-28 p-3 bg-purple-100 rounded-xl cursor-pointer">
                <TrendingUp className="w-6 h-6 text-current" />
                <span className="mt-2 text-sm font-semibold">Add Investment</span>
              </a>
              <a href='/#/adddonation' className="flex flex-col items-center justify-center h-28 p-3 bg-pink-100 rounded-xl cursor-pointer">
                <Heart className="w-6 h-6 text-current" />
                <span className="mt-2 text-sm font-semibold">Add Donation</span>
              </a>
            </div>

            {/* Close Button */}
            <div className="flex justify-center items-center">
              <button
                onClick={togglePopup}
                className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center"
              >
                <X className="w-6 h-6 text-gray-600" />
              </button>
            </div>
          </div>
        </>
      )}

      {/* New Mobile Navbar with Hole Design */}
      <nav className="fixed bottom-0 left-0 right-0 z-40">
        {/* Navigation Bar */}
        <div className="bg-white h-16 px-6 rounded-t-xl shadow-lg flex items-center justify-between relative">
          {/* Curved Notch for Add Button */}
          <div className="absolute left-1/2 -translate-x-1/2 -top-5 w-20 h-10 bg-transparent border-t-[16px] border-l-[16px] border-r-[16px] border-white rounded-t-full" />
          
          {/* Add Button */}
          <button
            onClick={togglePopup}
            className="absolute left-1/2 -translate-x-1/2 -top-7 w-14 h-14 rounded-full bg-black text-white flex items-center justify-center shadow-lg z-50"
            aria-label="Add new item"
          >
            <span className="text-2xl">+</span>
          </button>

          {/* Navigation Items */}
          <a href="/#/finance" className="flex flex-col items-center space-y-1">
            <Home className="w-6 h-6 text-emerald-500" />
            <span className="text-xs text-emerald-500 font-medium">Home</span>
          </a>
          <a href="/#/alltransactions" className="flex flex-col items-center space-y-1">
            <BarChart className="w-6 h-6 text-gray-500" />
            <span className="text-xs text-gray-500 font-medium">Transactions</span>
          </a>
          {/* Empty space for center button */}
          <div className="w-14" />
          <a href="/#/task" className="flex flex-col items-center space-y-1">
            <User className="w-6 h-6 text-gray-500" />
            <span className="text-xs text-gray-500 font-medium">Account</span>
          </a>
          <button onClick={handleLogout} className="flex flex-col items-center space-y-1">
            <LogOut className="w-6 h-6 text-gray-500" />
            <span className="text-xs text-gray-500 font-medium">Logout</span>
          </button>
        </div>
      </nav>
    </>
  );
}

