import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { axiosInstance } from '../API/authapi.tsx';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();

    // Prepare the data to be sent in the request
    const userData = {
      username: email,
      password: password,
    };

    try {
      // Make the Axios request to the server
      const response = await axiosInstance.post('/users/adduser', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Assuming your API returns a success message
      toast.success('Registration successful!');
      console.log(JSON.stringify(response.data));
    } catch (error) {
      // Handle registration failure, show error message using toastr
      toast.error(error.response.data.error);
      console.log(error); 
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col">
      {/* Decorative background */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full rounded-full bg-yellow-200/20" />
        <div className="absolute top-0 -right-1/2 w-full h-full rounded-full bg-green-200/20" />
        <div className="absolute -bottom-1/2 left-1/4 w-full h-full rounded-full bg-pink-200/20" />
      </div>
  
      <main className="flex-1 flex items-center justify-center p-6 relative">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center space-y-2">
            <h1 className="text-3xl font-bold tracking-tighter text-white">
              Create Your Account
            </h1>
            <p className="text-gray-400 text-sm">
              Join us and take control of your finances with ease.
            </p>
          </div>
  
          <div className="bg-gray-900/60 backdrop-blur-lg rounded-2xl p-8 space-y-6 border border-gray-800">
            <form onSubmit={handleSignup} className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="email" className="text-sm font-medium text-gray-200">
                  Username
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  required
                  className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
  
              <div className="space-y-2">
                <label htmlFor="password" className="text-sm font-medium text-gray-200">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
  
              <div className="space-y-2">
                <label htmlFor="confirmPassword" className="text-sm font-medium text-gray-200">
                  Confirm Password
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="••••••••"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
  
              <button
                type="submit"
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Sign Up
              </button>
            </form>
  
            <div className="text-center">
              <p className="text-sm text-gray-400">
                Already have an account?{' '}
                <a href="/login" className="font-medium text-blue-500 hover:text-blue-400">
                  Log in
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
  
};

export default Signup;
