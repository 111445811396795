import Login from "./Accounts/Login";
import Signup from "./Accounts/Signup";
import Home from "./Home/Home";
import Navbar from "./Navbar/Navbar";
import "react-toastify/dist/ReactToastify.css";
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Loss from "./Home/Loss/Loss";
import Profit from "./Home/Profit/Profit";
import Invest from "./Home/Investment/Invest";
import Savings from "./Home/Savings/Savings";
import Donations from "./Home/Donation/Donations";
import Credits from "./Home/Credit/Credits";
import Addprofit from "./Home/Profit/Addprofit";
import Addloss from "./Home/Loss/Addloss";
import AddInvest from "./Home/Investment/AddInvest";
import AddDonation from "./Home/Donation/AddDonation";
import AddSaving from "./Home/Savings/AddSaving";
import AddCredit from "./Home/Credit/AddCredit";
import Expenselist from "./Home/Loss/Expenselist";
import Profitlist from "./Home/Profit/Profitlist";
import AllData from "./Home/AllTransaction/AllData";
import Management from "./Managment/Home";
import AddManagementprofit from "./Managment/Profit/Addprofit";
import AddCustomer from "./Managment/Customers/AddCustomer";
import MainDashboard from "./Dashboard/Dashboard";
import Work from "./Work/Home";
import Customerslist from "./Managment/Customers/CustomersList";
import AddWork from "./Work/AddWork";
import AddEmploye from "./Work/Employe/AddEmploye";
import AddItem from "./Hotel/Add Bill/Bill";
import AddProduct from "./Hotel/Add product/Product";
import Hotel from "./Hotel/Dashboard/Home";
import UpdateCustomer from "./Managment/Customers/UpdateCustomer";
import UpdateProfit from "./Home/Profit/UpdateProfit";
import UpdateCredit from "./Home/Credit/UpdateCredit";
import ProductList from "./Hotel/Add product/ProductList";
import UpdateProduct from "./Hotel/Add product/UpdateProduct";
import ProductCategoryList from "./Hotel/Add product/ProductCategoryList";
import InvoiceList from "./Hotel/Add Bill/InvoiceList";
import UpdateExpense from "./Home/Loss/UpdateExpense";
import MoneyFlowScreen from "./Dashboard/Finance";



function App() {


  return (
    <Router>
      <div className="bg-slate-950 min-h-screen flex flex-col">
        {/* <Navbar /> */}
        <Routes>
          <Route path="/loss" element={<Loss />} />
          <Route path="/expense/updateexpense/:ExpenseId" element={<UpdateExpense />} />
          <Route path="/" element={<MoneyFlowScreen />} />
          <Route path="/finance" element={<Home />} />
          <Route path="/alltransactions" element={<AllData />} />
          <Route path="/profit" element={<Profit />} />
          <Route path="/accounts" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/invest" element={<Invest />} />
          <Route path="/saving" element={<Savings />} />
          <Route path="/donation" element={<Donations />} />
          <Route path="/expenses" element={<Expenselist />} />
          <Route path="/profits" element={<Profitlist />} />
          <Route path="/credit" element={<Credits />} />
          <Route path="/credit/updatecredit/:creditId" element={<UpdateCredit />} />
          <Route path="/addexpense" element={<Addloss />} />
          <Route path="/addprofit" element={<Addprofit />} />
          <Route path="/profit/updateprofit/:profitId" element={<UpdateProfit />} />
          <Route path="/addinvestment" element={<AddInvest />} />
          <Route path="/adddonation" element={<AddDonation />} />
          <Route path="/addsaving" element={<AddSaving />} />
          <Route path="/addcredit" element={<AddCredit />} />


          <Route path="/management" element={<Management/>}/>
          <Route path="/management/addprofit" element={<AddManagementprofit/>}/>
          <Route path="/management/customers" element={<Customerslist/>}/>
          <Route path="/management/addcustomer" element={<AddCustomer/>}/>
          <Route path="/management/updatecustomer/:customerId" element={<UpdateCustomer/>}/>

          <Route path="/work" element={<Work/>}/>
          <Route path="/work/addwork" element={<AddWork/>}/>

          <Route path="/employe/addemploye" element={<AddEmploye/>}/>

          <Route path="/hotel/addbill" element={<AddItem/>}/>
          <Route path="/hotel/allbills" element={<InvoiceList/>}/>
          <Route path="/hotel/addproduct" element={<AddProduct/>}/>
          <Route path="/hotel/updateproduct/:productId" element={<UpdateProduct/>}/>
          <Route path="/hotel/allproduct" element={<ProductCategoryList/>}/>
          <Route path="/hotel/allproduct/:category" element={<ProductList/>}/>
          <Route path="/hotel" element={<Hotel/>}/>


        </Routes>
      </div>
    </Router>
  );
}

export default App;
