import React from 'react'
import { LineChart, Line, XAxis, ResponsiveContainer } from 'recharts'

const data = [
  { value: 10, trend1: 30, trend2: 20 },
  { value: 40, trend1: 35, trend2: 50 },
  { value: 80, trend1: 40, trend2: 35 },
  { value: 120, trend1: 45, trend2: 40 },
  { value: 160, trend1: 50, trend2: 50 },
  { value: 200, trend1: 55, trend2: 45 },
  { value: 240, trend1: 65, trend2: 15 },
]

export default function HistoricalReportGraph() {
  return (
    <div className="w-full h-[300px] p-4"  style={{marginTop:'-15px'}}>
      <h2 className="text-lg font-semibold mb-4">Historical Report</h2>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20, // Increase bottom margin for more space below the X-axis
          }}
        >
          <XAxis
            dataKey="value"
            tickFormatter={(value) => `${value}k`}
            stroke="#888888"
            tickLine={false}
            axisLine={false}
            interval="preserveStartEnd" // Adjusts spacing by preserving start and end labels
          />
          <Line
            type="monotone"
            dataKey="trend1"
            stroke="#000000"
            strokeWidth={2}
            dot={false}
            activeDot={false}
          />
          <Line
            type="monotone"
            dataKey="trend2"
            stroke="#FF0000"
            strokeWidth={2}
            dot={false}
            activeDot={false}
          />
          {/* Dots for the start and end of trend1 */}
          <Line
            dataKey="trend1"
            stroke="none"
            dot={(props) => {
              const { cx, cy, index } = props
              if (index === 0 || index === data.length - 1) {
                return <circle cx={cx} cy={cy} r={4} fill="#000000" />
              }
              return null
            }}
          />
          {/* Dots for the start and end of trend2 */}
          <Line
            dataKey="trend2"
            stroke="none"
            dot={(props) => {
              const { cx, cy, index } = props
              if (index === 0 || index === data.length - 1) {
                return <circle cx={cx} cy={cy} r={4} fill="#FF0000" />
              }
              return null
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
