import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../API/authapi.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';


const Login = () => {
  const navigate = useNavigate(); 
  const [formData, setFormData] = useState({ 
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        const today = new Date();

        const response = await axiosInstance.post('/users/verifyuser');
        navigate('/')
      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }      }
    };
    fetchData();
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Make a POST request to your login endpoint
      toast.warning('Verifying...');
      const response = await axiosInstance.post('/users/login', formData);
  
      // Handle the response, e.g., store the token in local storage or redirect to another page
      console.log('Login successful', response.data);
      const { token } = response.data;
  
      // Store the token in localStorage
      localStorage.setItem('JWT_token', token);
      localStorage.setItem('theme', 'light');
  
      toast.success('Login successful!');
      window.location.href = '/';
    } catch (error) {
      toast.error('Username or Password Wrong');
      // Handle error, e.g., show an error message to the user
    }
  }; 

  return (
    <div className="min-h-screen bg-black flex flex-col">
      {/* Decorative background */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full rounded-full bg-yellow-200/20" />
        <div className="absolute top-0 -right-1/2 w-full h-full rounded-full bg-green-200/20" />
        <div className="absolute -bottom-1/2 left-1/4 w-full h-full rounded-full bg-pink-200/20" />
      </div>

      <main className="flex-1 flex items-center justify-center p-6 relative">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center space-y-2">
            <h1 className="text-3xl font-bold tracking-tighter text-white">
              Master Your Money Flow
            </h1>
            <p className="text-gray-400 text-sm">
              Track expenses, monitor profits, and manage your finances effortlessly.
            </p>
          </div>

          <div className="bg-gray-900/60 backdrop-blur-lg rounded-2xl p-8 space-y-6 border border-gray-800">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="username" className="text-sm font-medium text-gray-200">
                  Username
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="password" className="text-sm font-medium text-gray-200">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="••••••••"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-300">
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <a href="#" className="font-medium text-blue-500 hover:text-blue-400">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <button
                type="submit"
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Get Started
              </button>
            </form>

            <div className="text-center">
              <p className="text-sm text-gray-400">
                Don't have an account?{' '}
                <a href="/signup" className="font-medium text-blue-500 hover:text-blue-400">
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );

};

export default Login;
