import React from 'react';
import { createRoot } from 'react-dom';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import Addbutton from './Home/Add/Addbutton';
import DeviceUnavailableScreen from './Dashboard/DeviceUnavailableScreen';
import { isMobile, isIOS, isAndroid } from 'react-device-detect'; // Import the device detection utilities
import AddActionsPopup from './Components/AddButton';

const root = createRoot(document.getElementById('root'));

// Check for device availability and render accordingly
const renderApp = () => {
  if (!isMobile) {
    return <DeviceUnavailableScreen message="This app is currently unavailable for laptops." />;
  } else {
    return (
      <React.StrictMode>
        <App />
        <AddActionsPopup />
        <ToastContainer />
      </React.StrictMode>
    );
  } 
};

// Set the app element for react-modal
root.render(renderApp());
