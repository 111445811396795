import React from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const DateRangePicker = ({ onChange }) => {
  return (
    <RangePicker
      size="small"
      className="w-32"
      onChange={onChange} // Ensure this is correctly passed
      placeholder={['Start', 'End']}
    />
  );
};

export default DateRangePicker;
