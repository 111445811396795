import React, { useState } from "react";
import { useEffect } from "react";
import { axiosInstance } from "../API/authapi.tsx";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Moon, Sun } from "lucide-react";
import DateRangePicker from "../Components/DateRangePicker.jsx";
import { Button } from "antd";
import HistoricalReportGraph from "../Components/graph.jsx";

function Home() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [lossAmount, setLossAmount] = useState(0);
  const [profitAmount, setProfitAmount] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [dailySpend, setDailySpend] = useState(0);
  const [todayExpense, setTodayExpense] = useState(0);
  const [savingsAmount, setsavingsAmount] = useState(0);
  const [investmentAmount, setInvestment] = useState(0);
  const [donationAmount, setDonation] = useState(0);
  const [todayLimit, setTodayLimit] = useState(0);

  const toggleTheme = () => {
    // Toggle between dark and light themes
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);

    // Save theme preference to localStorage
    localStorage.setItem("theme", newTheme);
  };

  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark"; // Default to dark theme if no preference is saved
  };

  const [theme, setTheme] = useState(getInitialTheme());
  const currentDate = new Date();

  // Calculate the first day of the current month
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Format the date as "YYYY-MM-DD" for the input value
  const initialStartDate = formatDate(firstDayOfMonth);

  // Function to format the date as "YYYY-MM-DD"
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(null);



  const handleDateRangeChange = (dates) => {
    console.log(dates);

    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start.toISOString()); // Update startDate
      setEndDate(end.toISOString()); // Update endDate
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {

        const today = new Date();
        const start = startDate || today.toISOString();
        const end = endDate || today.toISOString();

        const response = await axiosInstance.post('/users/dashboard', {
          startDate: start,
          endDate: end,
        });

        const { totalExpense, totalProfit, totalBalance, totalSaving, totalInvestment, totalDonations, totalCredit } = response.data;

        if (totalExpense !== null) {
          setLossAmount(totalExpense);
        }
        if (totalCredit !== null) {
          setCreditAmount(totalCredit);
        }
        if (totalProfit !== null) {
          setProfitAmount(totalProfit);
        }
        if (totalBalance !== null) {
          setBalanceAmount(totalBalance);
        }
        if (totalSaving !== null) {
          setsavingsAmount(totalSaving);
        }
        if (totalInvestment !== null) {
          setInvestment(totalInvestment);
        }
        if (totalDonations !== null) {
          setDonation(totalDonations);
        }

        // ... update other state variables as needed

        const nextMonth = new Date(today);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        nextMonth.setDate(3);
        const daysLeft = Math.ceil((nextMonth - today) / (1000 * 60 * 60 * 24));
        console.log(daysLeft);

        // Calculate daily spend based on the remaining days
        const dailySpend = Math.round(Math.max(totalBalance / daysLeft, 0));
        setDailySpend(dailySpend);


        setIsLoading(false);
      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }
      }
    };

    fetchData();
  }, [startDate, endDate, navigate]);
  if (isLoading) {
    console.log(isLoading);
    return (
      <div className={`${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'} flex justify-center items-center h-screen`}>
        <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-black'}`}>Loading...</p>
      </div>

    );
  }

  else {
    return (
      <div className={`relative w-full p-6 flex flex-col gap-4 ${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'}`}>

        <div className="flex flex-row items-center justify-between space-x-2">
          <h1 className="text-2xl font-bold font-rubik">Dashboard</h1>
          <div className="flex items-center space-x-2">
            <DateRangePicker onChange={handleDateRangeChange} />
            <Button
              variant="ghost"
              size="icon"
              onClick={toggleTheme}
              className="w-10 h-10 p-0"
            >
              {theme === 'dark' ? (
                <Sun className="h-4 w-4" />
              ) : (
                <Moon className="h-4 w-4" />
              )}
            </Button>
          </div>
        </div>

        <div class="container scroll-1 z-20">
          <div class="card">
            <div className="w-full">
              <div className="BalanceBlue text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-32 font-rubik h-44 relative overflow-hidden ">
                <div className=" bg-slate-50 bg-opacity-10 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
                <div className=" bg-slate-50 bg-opacity-10 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
                <p className="font-light mb-4 text-slate-300">Your Wallet Balance</p>
                <h3 className="font-bold text-3xl md:text-4xl lg:text-6xl">
                  <span className="font-poppins text-2xl md:text-2xl mr-1">₹</span>

                  {balanceAmount}
                </h3>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="w-full ">
              <a href="/#/profit">
                <div class="bg-[#9Df870] text-black rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-32 font-rubik h-44 relative overflow-hidden">
                  <div class="bg-[#000000] bg-opacity-10 w-28 h-28 rounded-full absolute -top-4 right-5 -z-0"></div>
                  <div class="bg-[#000000] bg-opacity-10 w-40 h-40 rounded-full absolute -top-1 -right-10"></div>
                  <p className="font-light mb-4 text-black">Profit</p>
                  <div class="flex items-center">

                    <h3 className="font-bold text-3xl md:text-4xl lg:text-6xl text-black">
                      <span className="font-poppins text-2xl md:text-2xl mr-1 text-black">₹</span>
                      {profitAmount}
                    </h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="card">
            <div className="w-full ">
              <a href="/#/loss">
                <div className="bg-[#fb827a] text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-32 font-rubik h-44 relative overflow-hidden ">
                  <div className=" bg-slate-50 bg-opacity-10 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
                  <div className=" bg-slate-50 bg-opacity-10 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
                  <p className="font-light mb-4 text-slate-300">Loss</p>
                  <h3 className="font-bold text-3xl md:text-4xl lg:text-6xl">
                    <span className="font-poppins text-2xl md:text-2xl mr-1 ">₹</span>

                    {lossAmount}
                  </h3>
                </div>
              </a>
            </div>
          </div>


        </div>



        <div className="w-full ">
          <div className=" border border-[#bdbdbd] text-white rounded-2xl p-6 md:p-10 flex flex-col justify-center items-start md:h-32 font-rubik h-40 relative overflow-hidden ">
            <div className=" bg-gray-600 bg-opacity-5 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
            <div className=" bg-gray-600 bg-opacity-5 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
            <p className="font-normal mb-8 text-black">Today's  Spend Limit</p>
            <h3 className="font-bold text-3xl md:text-4xl lg:text-6xl text-black">
              <span className="font-poppins text-2xl md:text-2xl mr-1 text-black">₹</span>
              {dailySpend}
            </h3>
          </div>
        </div>




        <div className="flex gap-4 font-rubik">
          <a
            href="/#/invest"
            className="bg-[#e6e6ff] text-black w-full md:w-1/2 rounded-2xl p-4 pl-7 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36 h-32 relative overflow-hidden"
          >
            <div className="bg-gray-400 bg-opacity-5 w-16 h-16 rounded-full absolute -top-5 right-0 -z-0"></div>
            <div className="bg-gray-400 bg-opacity-5 w-24 h-24 rounded-full absolute -top-1 -right-10"></div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Investment</p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-green-600 ml-2"
              >
                <path
                  d="M3 17L7 13L11 17L21 7M21 7V13M21 7H15"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h3 className="font-bold text-4xl md:text-3xl lg:text-4xl flex items-center">
              <span className="font-poppins text-3xl md:text-xl mr-1">₹</span>
              <p className="ml-1.5 text-3xl md:text-xl">{investmentAmount}</p>
            </h3>
          </a>
          <a
            href="/#/saving"
            className="bg-[#ffefc7] text-black w-full md:w-1/2 rounded-2xl p-4 pl-7 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36 h-32 relative overflow-hidden"
          >
            <div className="bg-gray-400 bg-opacity-5 w-16 h-16 rounded-full absolute -top-5 right-0 -z-0"></div>
            <div className="bg-gray-400 bg-opacity-5 w-24 h-24 rounded-full absolute -top-1 -right-10"></div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Savings</p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-red-600 ml-2"
              >
                <path
                  d="M3 7L7 11L11 7L21 17M21 17V11M21 17H15"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h3 className="font-bold text-4xl md:text-3xl lg:text-4xl flex items-center">
              <span className="font-poppins text-3xl md:text-xl mr-1">₹</span>
              <p className="ml-1.5 text-3xl md:text-xl">{savingsAmount}</p>
            </h3>
          </a>
        </div>

        <div className="flex gap-4 font-rubik">
          <a
            href="/#/credit"
            className="bg-[#ffdde8] text-black w-full md:w-1/2 rounded-2xl p-4 pl-7 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36 h-32 relative overflow-hidden"
          >
            <div className="bg-gray-400 bg-opacity-5 w-16 h-16 rounded-full absolute -top-5 right-0 -z-0"></div>
            <div className="bg-gray-400 bg-opacity-5 w-24 h-24 rounded-full absolute -top-1 -right-10"></div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Credits</p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-green-600 ml-2"
              >
                <path
                  d="M3 17L7 13L11 17L21 7M21 7V13M21 7H15"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h3 className="font-bold text-4xl md:text-3xl lg:text-4xl flex items-center">
              <span className="font-poppins text-3xl md:text-xl mr-1">₹</span>
              <p className="ml-1.5 text-3xl md:text-xl">{creditAmount}</p>
            </h3>
          </a>
          <a
            href="/#/donation"
            className="bg-[#c7f4e2] text-black w-full md:w-1/2 rounded-2xl p-4 pl-7 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36 h-32 relative overflow-hidden"
          >
            <div className="bg-gray-400 bg-opacity-5 w-16 h-16 rounded-full absolute -top-5 right-0 -z-0"></div>
            <div className="bg-gray-400 bg-opacity-5 w-24 h-24 rounded-full absolute -top-1 -right-10"></div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Donations</p>
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-red-600 ml-2"
              >
                <path
                  d="M3 7L7 11L11 7L21 17M21 17V11M21 17H15"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>               <h3 className="font-bold text-4xl md:text-3xl lg:text-4xl flex items-center">
              <span className="font-poppins text-3xl md:text-xl mr-1">₹</span>
              <p className="ml-1.5 text-3xl md:text-xl">{donationAmount}</p>
            </h3>
          </a>
        </div>


        <HistoricalReportGraph />



      </div>

    );
  }
}


export default Home;
